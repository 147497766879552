<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <path
    id="패스_7"
    data-name="패스 7"
    d="M14,6.667c0,4.667-6,8.667-6,8.667s-6-4-6-8.667a6,6,0,1,1,12,0Z"
    fill="none"
    stroke="#8693ad"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
  />
  <circle
    id="타원_3"
    data-name="타원 3"
    cx="2"
    cy="2"
    r="2"
    transform="translate(6 4.667)"
    fill="none"
    stroke="#8693ad"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
  />
</svg>
