import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfo } from '../../models/user-info';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MsalService } from '@azure/msal-angular';
import { StorageService } from '../storage/storage.service';

const { userRoleMap } = environment;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  // signed user subject
  private readonly _user$: BehaviorSubject<UserInfo> = new BehaviorSubject(null);
  // show guide subject
  private readonly _showGuide$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  // 'true' when access to the app is failed and 'Retry' option wasn't clicked (e.g. user clicks 'OK'/close button when access to backend is failed)
  private readonly _isBackendAccessInFailedState$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private authService: MsalService,
    private storageService: StorageService
  ) { }

  /**
   * set signed user
   * @param user signed user
   */
  set user(user: UserInfo) {
    this._user$.next(user);
  }

  /**
   * get signed user as stream
   */
  get user$(): Observable<UserInfo> {
    return this._user$.asObservable();
  }

  set isBackendAccessInFailedState(value: boolean) {
    this._isBackendAccessInFailedState$.next(value);
  }

  get isBackendAccessInFailedState$(): Observable<boolean> {
    return this._isBackendAccessInFailedState$.asObservable();
  }

  /**
   * return true when user is signed in
   */
  get isSignedIn$(): Observable<boolean> {
    return this.user$.pipe(map((user) => !!user));
  }

  /**
   * return true when user is admin
   */
  get isAdmin$(): Observable<boolean> {
    return this.user$.pipe(map((user) => user?.role === userRoleMap.admin.value));
  }

  /**
   * set showGuide state
   * @param state state
   */
  set showGuide(state: boolean) {
    this._showGuide$.next(state);
  }

  /**
   * return showGuide state as stream
   */
  get showGuide$(): Observable<boolean> {
    return this._showGuide$.asObservable();
  }


  /**
   * process logout
   */
  logout(): void {
    this.authService.logout().subscribe(() => {
      this.user = null;
      this.storageService.user = null;
      this.storageService.token = null;
    });
  }
}
