import { UserRoleMap } from '../app/models/user-role-map';
import { UserRole } from '../app/models/user-info';
import { LocationHierarchyType } from '../app/models/location-hierarchy';
import { LocationTypeMap } from '../app/models/location-type-map';
import { LocationDetailTypeMap } from '../app/models/location-detail-type-map';

export const commonEnvironments = {
  // userRoleMap to display user role in UI
  userRoleMap: {
    admin: { value: 'admin', label: 'Admin' },
    editor: { value: 'editor', label: 'Editor' },
    user: { value: 'user', label: 'Regular User' }
  } as { [k: string]: UserRoleMap },
  // user role who can create
  creatableUserRoles: ['admin'] as UserRole[],
  // user role who can edit
  editableUserRoles: ['admin', 'editor'] as UserRole[],
  // Inactivity period in minutes, after which user will be logged out
  inactivityPeriodInMinutes: 60,
  // Time in munutes to display alert modal during user inactivity, before user is logged out
  inactivityAlertInMinutes: 5,
  // default page size for pageable table
  defaultPageSize: 10,
  // default page size options for pageable table
  defaultSizeOptions: [10, 20, 50],
  // directory type for base structure
  baseStructureDirectories: ['hierarchy', 'campus', 'lob'] as LocationHierarchyType[],
  // directory type for physical structure
  physicalStructureDirectory: ['hierarchy', 'region', 'state', 'city'] as LocationHierarchyType[],
  // directory type for management structure
  managementStructureDirectory: [
    'hierarchy',
    'lob',
    'region',
    'district',
    'area',
    'lob-cost-center'
  ] as LocationHierarchyType[],
  // locationTypeMap to display user location type in UI
  locationTypeMap: {
    hierarchy: { type: 'hierarchy', label: 'Hierarchy' },
    campus: { type: 'campus', label: 'Campus Name' },
    lob: { type: 'lob', label: 'Region' },
    region: { type: 'region', label: 'Region' },
    state: { type: 'state', label: 'State' },
    city: { type: 'city', label: 'City' },
    district: { type: 'district', label: 'District' },
    area: { type: 'area', label: 'Area' },
    lobCC: { type: 'lob-cost-center', label: 'LOB Cost Center' },
    inventoryOrg: { type: 'inventory-org', label: 'Inventory Org' }
  } as { [k: string]: LocationTypeMap },
  // locationDetailTypeMap to display user location detail type in UI
  locationDetailTypeMap: {
    auxiliaryStorage: { type: 'auxiliary-storage', label: 'Auxiliary Storage' },
    counter: { type: 'counter', label: 'Counter' },
    distributionCenter: { type: 'distribution-center', label: 'Distribution Center' },
    marketDistributionCenter: { type: 'market-distribution-center', label: 'Market Distribution Center' },
    officeSpace: { type: 'office-space', label: 'Office Space' },
    outsideStorageYard: { type: 'outside-storage-yard', label: 'Outside Storage Yard' },
    shipHubs: { type: 'ship-hubs', label: 'ShipHubs' },
    showroom: { type: 'showroom', label: 'Showroom' },
    salesOffice: { type: 'sales-office', label: 'Sales Office' },
    warehouse: { type: 'warehouse', label: 'Warehouse' }
  } as { [k: string]: LocationDetailTypeMap },
  // These are roots in the location hierarchy
  rootLocationHierarchies: [
    {
      _id: 'krv541stg98', // some id
      id: 'krv541stg98',
      root: 'base',
      type: 'hierarchy',
      nodeType: 'hierarchy',
      name: 'Base Structure',
      children: null
    },
    {
      _id: '2m464neofm8', // some id
      id: '2m464neofm8',
      root: 'physical',
      type: 'hierarchy',
      nodeType: 'hierarchy',
      name: 'Physical Structure',
      children: null
    },
    {
      _id: '2lsq6j0vil8', // some id
      id: '2lsq6j0vil8', // some id
      root: 'management',
      type: 'hierarchy',
      nodeType: 'hierarchy',
      name: 'Management Structure',
      children: null
    }
  ],
  // message interval
  messageInterval: 3000,
  // alarm message interval
  messageIntervalAlarm: 10000,
  // success message close timer in second
  successMessageCloseTimer: 7,
  // available time options
  timeOptions: [
    '00:00:00',
    '00:30:00',
    '01:00:00',
    '01:30:00',
    '02:00:00',
    '02:30:00',
    '03:00:00',
    '03:30:00',
    '04:00:00',
    '04:30:00',
    '05:00:00',
    '05:30:00',
    '06:00:00',
    '06:30:00',
    '07:00:00',
    '07:30:00',
    '08:00:00',
    '08:30:00',
    '09:00:00',
    '09:30:00',
    '10:00:00',
    '10:30:00',
    '11:00:00',
    '11:30:00',
    '12:00:00',
    '12:30:00',
    '13:00:00',
    '13:30:00',
    '14:00:00',
    '14:30:00',
    '15:00:00',
    '15:30:00',
    '16:00:00',
    '16:30:00',
    '17:00:00',
    '17:30:00',
    '18:00:00',
    '18:30:00',
    '19:00:00',
    '19:30:00',
    '20:00:00',
    '20:30:00',
    '21:00:00',
    '21:30:00',
    '22:00:00',
    '22:30:00',
    '23:00:00',
    '23:30:00',
    '00:00:00'
  ],
  // Display Duration options for Calendar & Events
  calendarEventsDisplayDurations: [1, 2, 3, 5, 7, 14, 30, 60, 90],
  // available pro-pickup duration options
  proPickupDurationOptions: ['1 hr', '2 hrs'],
  detailHeaderDisplayCount: 10,
  productOfferingDisplayCount: 5,
  // the maximum number of Brands to display in Child Location Details view
  brandsDisplayCount: 5,
  // the maximum number of Professional Associations to display in Child Location Details view
  professionalAssociationsMaxDisplayCount: 5,
  // the maximum number of Merchandising Videos to display in Child Location Details view
  merchandisingVideosMaxDisplayCount: 5,
  // the maximum number of Merchandising Banners to display in Child Location Details view
  merchandisingBannersMaxDisplayCount: 5,
  valueAddedServiceDisplayCount: 5,
  servicesAndCertificationDisplayCount: 5,
  placeHolderImage: '/assets/images/logo.svg',
  serviceHubNewTicketLink: 'https://wolseley.service-now.com/servicehub/?id=sc_cat_item&sys_id=4281ff251b1f78502a70ffbf034bcb8d'
};
