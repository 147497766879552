import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SuccessMessageService } from '../success-message.service';
import { randomKey } from '../../../utils/random.util';

@Component({
  selector: 'app-success-message-outlet',
  templateUrl: './success-message-outlet.component.html',
  styleUrls: ['./success-message-outlet.component.scss']
})
export class SuccessMessageOutletComponent implements OnInit, AfterViewInit, OnDestroy {
  // view container ref
  @ViewChild('container', { read: ViewContainerRef }) public viewContainerRef: ViewContainerRef;
  // random id
  private _id = randomKey();

  constructor(private successMessageService: SuccessMessageService) {}

  ngOnInit(): void {}

  /**
   * set viewContainerRef to service when view initialized
   */
  ngAfterViewInit(): void {
    this.successMessageService.appendViewContainer(this._id, this.viewContainerRef);
  }

  /**
   * destroy view container when destroying
   */
  ngOnDestroy(): void {
    this.successMessageService.destroyViewContainer(this._id);
  }
}
