import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations';

export const MOBILE_SLIDE_ANIMATION = trigger('mobileSlide', [
  state(
    'void',
    style({
      transform: 'translateX(100%)'
    })
  ),
  state(
    'slide',
    style({
      transform: 'translateX(0)'
    })
  ),
  transition('void <=> slide', animate('.3s ease-out'))
]);

export const MESSAGE_ITEM_ANIMATION = trigger('shake', [
  transition('void => *', [
    animate(
      '.03s',
      style({
        transform: 'translateX(-15px)'
      })
    ),
    animate(
      '.03s',
      style({
        transform: 'translateX(15px)'
      })
    ),
    animate(
      '.03s',
      style({
        transform: 'translateX(-5px)'
      })
    ),
    animate(
      '.03s',
      style({
        transform: 'translateX(5px)'
      })
    ),
    animate(
      '.03s',
      style({
        transform: 'translateX(-12px)'
      })
    ),
    animate(
      '.03s',
      style({
        transform: 'translateX(12px)'
      })
    ),
    animate(
      '.03s',
      style({
        transform: 'translateX(-3px)'
      })
    ),
    animate(
      '.03s',
      style({
        transform: 'translateX(3px)'
      })
    )
  ])
]);

export const SEARCH_SLIDE_ANIMATION = trigger('routerAnimation', [
  transition('search => detail', [
    query(':enter', [
      style({
        transform: 'translateX(100%)'
      })
    ]),
    query(':enter', [
      animate(
        '.3s ease-out',
        style({
          transform: 'translateX(0)'
        })
      )
    ]),
    query(':enter', animateChild())
  ]),
  transition('detail => search', [
    query(':leave', [
      style({
        transform: 'translateX(0)'
      })
    ]),
    query(':leave', [
      animate(
        '.3s ease-out',
        style({
          transform: 'translateX(100%)'
        })
      )
    ]),
    query(':leave', animateChild())
  ])
]);
