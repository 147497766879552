<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g transform="translate(-1.568 -1.568)">
    <circle
      cx="5.27"
      cy="5.27"
      r="5.27"
      transform="translate(2.351 2.351)"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      d="M16.459,16.459,13.05,13.05"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </g>
</svg>
