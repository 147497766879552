<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g transform="translate(-0.67555539,-0.75)" id="trash-2">
    <path
      fill="none"
      stroke="#8693ad"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M 2.182,4.364 H 15.273"
    />
    <path
      fill="none"
      stroke="#8693ad"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M 13.818,4.364 V 14.545 A 1.455,1.455 0 0 1 12.364,16 H 5.091 A 1.455,1.455 0 0 1 3.636,14.545 V 4.364 m 2.182,0 V 2.909 A 1.455,1.455 0 0 1 7.273,1.455 h 2.909 a 1.455,1.455 0 0 1 1.455,1.455 v 1.454"
      data-name="패스 45"
    />
    <path
      fill="none"
      stroke="#8693ad"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="m 7.273,8 v 4.364"
      data-name="패스 46"
    />
    <path
      fill="none"
      stroke="#8693ad"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="m 10.182,8 v 4.364"
    />
  </g>
</svg>
