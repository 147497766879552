import { Component, Input, OnInit } from '@angular/core';
import { IconBaseComponent } from '../icon-base/icon-base.component';

@Component({
  selector: 'app-icon-directory',
  templateUrl: './icon-directory.component.html',
  styleUrls: ['./icon-directory.component.scss']
})
export class IconDirectoryComponent extends IconBaseComponent implements OnInit {
  @Input() nodeType: string;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
