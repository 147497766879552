<svg xmlns="http://www.w3.org/2000/svg" width="6.828" height="10.828" viewBox="0 0 6.828 10.828">
  <path
    data-name="arrow 8px"
    d="M0,8,4,4,0,0"
    transform="translate(1.414 1.414)"
    fill="none"
    stroke="#fff"
    stroke-linecap="round"
    stroke-miterlimit="10"
    stroke-width="2"
  />
</svg>
