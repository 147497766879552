import { Component, Inject } from '@angular/core';
import { MODAL_DATA } from 'src/app/shared-components/modal/modal.service';
import { SubscriptionService } from '../../../../services/subscription/subscription.service';
import { IUserInactivityAlertModalData } from './user-inactivity-alert-modal-data';

@Component({
  selector: 'user-inactivity-alert-modal.component',
  templateUrl: './user-inactivity-alert-modal.component.html',
  styleUrls: ['./user-inactivity-alert-modal.component.scss'],
  providers: [SubscriptionService]
})
export class UserInactivityAlertModalComponent {
  // remaing time
  remainingMinutes: number;
  remainingSeconds: number;

  // the suffix to append to 'minute'/'second' (used for single/plural form)
  minutesSuffix: 's' | '' = '';
  secondsSuffix: 's' | '' = '';

  constructor(
    @Inject(MODAL_DATA) private data: IUserInactivityAlertModalData,
    private subscriptionService: SubscriptionService
  ) {
    this.subscribeRemainingTime();
  }

  private subscribeRemainingTime(): void {
    const sub = this.data.remainingTimeInSec.subscribe(totalSeconds => {
      this.remainingMinutes = Math.floor(totalSeconds / 60);
      this.minutesSuffix = this.remainingMinutes === 1 ? '' : 's';

      this.remainingSeconds = totalSeconds % 60;
      this.secondsSuffix = this.remainingSeconds === 1 ? '' : 's';
    });

    this.subscriptionService.store(`${this.constructor.name}.subscribeRemainingTime`, sub);
  }
}
