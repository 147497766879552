<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g transform="translate(-0.727 -0.727)">
    <circle
      cx="7.273"
      cy="7.273"
      r="7.273"
      transform="translate(1.455 1.455)"
      fill="none"
      stroke="#05446a"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      d="M8.727,4.364V8.727l2.909,1.455"
      fill="none"
      stroke="#05446a"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </g>
</svg>
