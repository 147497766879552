import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconBaseComponent } from './icon-base/icon-base.component';
import { IconLogoutComponent } from './icon-logout/icon-logout.component';
import { IconHomeComponent } from './icon-home/icon-home.component';
import { IconClockComponent } from './icon-clock/icon-clock.component';
import { IconHierarchyComponent } from './icon-hierarchy/icon-hierarchy.component';
import { IconSearchComponent } from './icon-search/icon-search.component';
import { IconArrowDownComponent } from './icon-arrow-down/icon-arrow-down.component';
import { IconSortArrowDownComponent } from './icon-sort-arrow-down/icon-sort-arrow-down.component';
import { IconDirectoryComponent } from './icon-directory/icon-directory.component';
import { IconDirectoryOpenComponent } from './icon-directory-open/icon-directory-open.component';
import { IconCounterComponent } from './icon-counter/icon-counter.component';
import { IconWarehouseComponent } from './icon-warehouse/icon-warehouse.component';
import { IconYardComponent } from './icon-yard/icon-yard.component';
import { IconBigChevronRightComponent } from './icon-big-chevron-right/icon-big-chevron-right.component';
import { IconBackCircleComponent } from './icon-back-circle/icon-back-circle.component';
import { IconCheckCircleFillComponent } from './icon-check-circle-fill/icon-check-circle-fill.component';
import { IconEditComponent } from './icon-edit/icon-edit.component';
import { IconAddSquareComponent } from './icon-add-square/icon-add-square.component';
import { IconMapPinComponent } from './icon-map-pin/icon-map-pin.component';
import { IconCalendarComponent } from './icon-calendar/icon-calendar.component';
import { IconUsersComponent } from './icon-users/icon-users.component';
import { IconBuildingComponent } from './icon-building/icon-building.component';
import { IconGarageComponent } from './icon-garage/icon-garage.component';
import { IconAptComponent } from './icon-apt/icon-apt.component';
import { IconHomeTreeComponent } from './icon-home-tree/icon-home-tree.component';
import { IconExclamationComponent } from './icon-exclamation/icon-exclamation.component';
import { IconChevronRightComponent } from './icon-chevron-right/icon-chevron-right.component';
import { IconCloseComponent } from './icon-close/icon-close.component';
import { IconMenuComponent } from './icon-menu/icon-menu.component';
import { IconChevronRightGreyComponent } from './icon-chevron-right-grey/icon-chevron-right-grey.component';
import { IconDeleteComponent } from './icon-delete/icon-delete.component';
import { IconCheckOutlineComponent } from './icon-check-outline/icon-check-outline.component';
import { IconChangeComponent } from './icon-change/icon-change.component';
import { IconRevertComponent } from './icon-revert/icon-revert.component';
import { IconSaveComponent } from './icon-save/icon-save.component';

@NgModule({
  declarations: [
    IconBaseComponent,
    IconLogoutComponent,
    IconHomeComponent,
    IconClockComponent,
    IconHierarchyComponent,
    IconSearchComponent,
    IconArrowDownComponent,
    IconSortArrowDownComponent,
    IconDirectoryComponent,
    IconDirectoryOpenComponent,
    IconCounterComponent,
    IconWarehouseComponent,
    IconYardComponent,
    IconBigChevronRightComponent,
    IconBackCircleComponent,
    IconCheckCircleFillComponent,
    IconEditComponent,
    IconAddSquareComponent,
    IconMapPinComponent,
    IconCalendarComponent,
    IconUsersComponent,
    IconBuildingComponent,
    IconGarageComponent,
    IconAptComponent,
    IconHomeTreeComponent,
    IconExclamationComponent,
    IconChevronRightComponent,
    IconCloseComponent,
    IconMenuComponent,
    IconChevronRightGreyComponent,
    IconDeleteComponent,
    IconCheckOutlineComponent,
    IconChangeComponent,
    IconRevertComponent,
    IconSaveComponent
  ],
  exports: [
    IconLogoutComponent,
    IconHomeComponent,
    IconClockComponent,
    IconHierarchyComponent,
    IconSearchComponent,
    IconArrowDownComponent,
    IconSortArrowDownComponent,
    IconDirectoryComponent,
    IconDirectoryOpenComponent,
    IconWarehouseComponent,
    IconYardComponent,
    IconCounterComponent,
    IconBigChevronRightComponent,
    IconBackCircleComponent,
    IconCheckCircleFillComponent,
    IconEditComponent,
    IconAddSquareComponent,
    IconMapPinComponent,
    IconCalendarComponent,
    IconUsersComponent,
    IconBuildingComponent,
    IconGarageComponent,
    IconAptComponent,
    IconHomeTreeComponent,
    IconExclamationComponent,
    IconChevronRightComponent,
    IconCloseComponent,
    IconMenuComponent,
    IconChevronRightGreyComponent,
    IconDeleteComponent,
    IconCheckOutlineComponent,
    IconChangeComponent,
    IconRevertComponent,
    IconSaveComponent
  ],
  imports: [CommonModule]
})
export class IconsModule {}
