import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-chevron-right-grey',
  templateUrl: './icon-chevron-right-grey.component.html',
  styleUrls: ['./icon-chevron-right-grey.component.scss']
})
export class IconChevronRightGreyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
