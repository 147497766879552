<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="그룹_84" data-name="그룹 84">
    <path
      id="패스_25"
      data-name="패스 25"
      d="M12,0,0,6V24H24V6Zm0,2.236L21.527,7H2.473ZM18,22H13V20H11v2H6V13H18Zm4,0H20V11H4V22H2V9H22Z"
      fill="#80d0ff"
    />
  </g>
</svg>
