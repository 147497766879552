import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingReplacerComponent } from './loading-replacer.component';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  declarations: [LoadingReplacerComponent],
  exports: [LoadingReplacerComponent],
  imports: [CommonModule, SpinnerModule]
})
export class LoadingReplacerModule {}
