<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <circle cx="2" cy="2" r="2" transform="translate(6)" fill="#8693ad" />
  <path
    d="M10.667,5.667V4.333A1.335,1.335,0,0,0,9.333,3H6.667A1.335,1.335,0,0,0,5.333,4.333V5.667H0V7H16V5.667Z"
    transform="translate(0 1)"
    fill="#8693ad"
  />
  <path
    d="M1,13.167a.667.667,0,0,0,.667.667h12a.667.667,0,0,0,.667-.667V6.5H1ZM5.244,8.383l2.939.482,1.1-1.1Z"
    transform="translate(0.333 2.167)"
    fill="#8693ad"
  />
</svg>
