import { Component, Input, OnInit } from '@angular/core';
import { AppSpinnerAlign } from '../spinner/spinner.component';

@Component({
  selector: 'app-loading-replacer',
  templateUrl: './loading-replacer.component.html',
  styleUrls: ['./loading-replacer.component.scss']
})
export class LoadingReplacerComponent implements OnInit {
  // loading state
  @Input() loading = false;
  // size
  @Input() size = 20;
  // height
  @Input() height: number;
  // loading align
  @Input() horizontalAlign: AppSpinnerAlign = 'left';

  constructor() {}

  ngOnInit(): void {}
}
