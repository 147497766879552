import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-base',
  template: ''
})
export class IconBaseComponent implements OnInit {
  // clickable state
  @Input() @HostBinding('class.clickable') clickable = false;
  // icon default class
  @HostBinding('class.ldm-icon') iconClass = true;

  constructor() {}

  ngOnInit(): void {}
}
