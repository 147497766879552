import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appButton]'
})
export class ButtonDirective {
  // button default class
  @HostBinding('class.ts-button') class = true;

  constructor() {}
}
