<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g transform="translate(-1.6 -1.6)">
    <rect
      width="14.4"
      height="14.4"
      rx="1.6"
      transform="translate(2.4 2.4)"
      fill="none"
      stroke="#1c88c8"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      d="M9.6,6.4v6.4"
      fill="none"
      stroke="#1c88c8"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      d="M6.4,9.6h6.4"
      fill="none"
      stroke="#1c88c8"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </g>
</svg>
