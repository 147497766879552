import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageOutletComponent } from './message-outlet/message-outlet.component';
import { MessageItemComponent } from './message-item/message-item.component';
import { IconsModule } from '../icons/icons.module';
import { DividerModule } from '../../components/common/divider/divider.module';
import { MessageMobileOutletComponent } from './message-mobile-outlet/message-mobile-outlet.component';

@NgModule({
  declarations: [MessageOutletComponent, MessageItemComponent, MessageMobileOutletComponent],
  exports: [MessageOutletComponent, MessageMobileOutletComponent],
  imports: [CommonModule, IconsModule, DividerModule],
  entryComponents: [MessageItemComponent]
})
export class MessageModule {}
