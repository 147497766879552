import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppService } from './app.service';
import { SubscriptionService } from '../subscription/subscription.service';
import { UserInactivityAlertModalComponent } from '../../components/common/modals/user-inactivity-alert-modal/user-inactivity-alert-modal.component';
import { IUserInactivityAlertModalData } from '../../components/common/modals/user-inactivity-alert-modal/user-inactivity-alert-modal-data';
import { ModalService } from '../../shared-components/modal/modal.service';

@Injectable()
export class UserInactivityTrackingService {

  private renderer: Renderer2;

  private lastInteraction: Date = new Date();
  private inactivityPeriodInSecs: number = environment.inactivityPeriodInMinutes * 60;
  private inactivityAlertInSecs: number = environment.inactivityAlertInMinutes * 60;
  private alertModalDisplayed: boolean = false;

  private remainingTimeInSec$: Subject<number> = new Subject();

  constructor(
    private rendererFactory2: RendererFactory2,
    private appService: AppService,
    private modalService: ModalService,
    private subscriptionService: SubscriptionService
  ) {
  }

  startTracking(): void {
    // listen to 'mousemove' event
    this.renderer = this.rendererFactory2.createRenderer(null, null);
    this.renderer.listen('document', 'mousemove', () => {
      // store last action
      this.lastInteraction = new Date();
      // close alert modal, if it was open
      this.tryCloseAlertModal();
    });

    // run interval every 1 sec
    const sub = interval(1000).subscribe(() => {
      const timeDiffInMs = new Date().getTime() - this.lastInteraction.getTime();
      const timeDiffInSecs = Math.floor(timeDiffInMs / 1000);
      const timeToLogout = this.inactivityPeriodInSecs - timeDiffInSecs;
      if (timeToLogout <= 0) {
        this.tryCloseAlertModal();
        this.appService.logout();
      }
      else if (timeToLogout <= this.inactivityAlertInSecs) {
        // open alert modal, if not opened yet
        this.tryOpenAlertModal();

        // provide remaining time to subscribers
        this.remainingTimeInSec$.next(timeToLogout);
      }
    });
    this.subscriptionService.store(`${this.constructor.name}.startTracking.interval`, sub);
  }

  private tryOpenAlertModal(): void {
    if (this.alertModalDisplayed) {
      // ignore if modal already displayed
      return;
    }

    const modalData: IUserInactivityAlertModalData = {
      remainingTimeInSec: this.remainingTimeInSec$.asObservable()
    };

    this.alertModalDisplayed = true;
    const modalOptions = {
      data: modalData,
      onClose: () => {
        this.alertModalDisplayed = false;
      }
    };

    this.modalService.open(UserInactivityAlertModalComponent, modalOptions);
  }

  private tryCloseAlertModal(): void {
    if (!this.alertModalDisplayed) {
      // ignore if modal not displayed
      return;
    }

    this.modalService.close();
    this.alertModalDisplayed = false;
  }

}
