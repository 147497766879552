import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { detectScreenWidth } from './utils/detector.util';
import { NavigationStart, Router } from '@angular/router';
import { SuccessMessageService } from './shared-components/success-message/success-message.service';
import { SubscriptionService } from './services/subscription/subscription.service';
import { Observable } from 'rxjs';
import { AppService } from './services/components/app.service';
import { UserInactivityTrackingService } from './services/components/user-inactivity-tracking.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [SubscriptionService, UserInactivityTrackingService]
})
export class AppComponent implements OnInit {
  // is mobile
  isMobile = false;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private subscriptionService: SubscriptionService,
    private successMessageService: SuccessMessageService,
    private appService: AppService,
    private userInactivityTrackingService: UserInactivityTrackingService
  ) {}

  ngOnInit(): void {
    this.onWindowResize();
    this._subscribeRouterChange();
    this.userInactivityTrackingService.startTracking();
  }

  get isLoginCompleted(): Observable<boolean> {
    return this.appService.isSignedIn$;
  }

  get isAppInFailedState(): Observable<boolean> {
    return this.appService.isBackendAccessInFailedState$;
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.isMobile = detectScreenWidth() < 768;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * subscribe router change
   */
  private _subscribeRouterChange(): void {
    const sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.successMessageService.destroyMessageItem();
      }
    });

    this.subscriptionService.store('_subscribeRouterChange', sub);
  }
}
