import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessMessageItemComponent } from './success-message-item/success-message-item.component';
import { SuccessMessageOutletComponent } from './success-message-outlet/success-message-outlet.component';
import { IconsModule } from '../icons/icons.module';

@NgModule({
  declarations: [SuccessMessageItemComponent, SuccessMessageOutletComponent],
  exports: [SuccessMessageOutletComponent],
  imports: [CommonModule, IconsModule]
})
export class SuccessMessageModule {}
