import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MessageType } from '../../message/message-item/message-item.component';
import { MESSAGE_ITEM_ANIMATION } from '../../../utils/animation.util';
import { environment } from '../../../../environments/environment';

const { successMessageCloseTimer } = environment;

@Component({
  selector: 'app-success-message-item',
  templateUrl: './success-message-item.component.html',
  styleUrls: ['./success-message-item.component.scss'],
  animations: [MESSAGE_ITEM_ANIMATION]
})
export class SuccessMessageItemComponent implements OnInit, OnDestroy {
  // message
  @Input() message: string;
  // message type
  @Input() @HostBinding('attr.ts-message-type') type: MessageType;
  // message close emitter
  @Output() messageClose: EventEmitter<void> = new EventEmitter<void>();
  // shake animation
  @HostBinding('@shake') animation = 'shake';
  // count
  private _count = successMessageCloseTimer;
  // timer
  private _timer;

  constructor() {}

  ngOnInit(): void {
    this._startCountdown();
  }

  ngOnDestroy(): void {
    clearTimeout(this._timer);
  }

  /**
   * set count
   * @param count count
   */
  set count(count: number) {
    this._count = count;
  }

  /**
   * return count
   */
  get count(): number {
    return this._count;
  }

  /**
   * countdown only started when undo callback is set
   */
  private _startCountdown(): void {
    this._timer = setInterval(() => {
      this._count--;

      if (this._count <= 0) {
        this.messageClose.emit();
      }
    }, 1000);
  }
}
