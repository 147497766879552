import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  OnDestroy,
  Type,
  ViewContainerRef
} from '@angular/core';
import { ModalBackgroundComponent } from './modal-background/modal-background.component';
import { ModalContentWrapperComponent } from './modal-content-wrapper/modal-content-wrapper.component';
import { ModalOutletComponent } from './modal-outlet/modal-outlet.component';
import { Subscription } from 'rxjs';

export const MODAL_DATA = 'APP_MODAL_DATA';

export type ModalPosition = 'center' | 'top';
export interface ModalOptions {
  // set modal data
  data?: any;
  // set modal position
  // default is top
  position?: ModalPosition;
  // set close callback
  onClose?: (returns?: any) => void;
}

export interface IModalItem {
  // close callback for modal
  onClose?: (returns?: any) => void;
  // content wrapper
  contentWrapper: ComponentRef<ModalContentWrapperComponent>;
  // background
  background: ComponentRef<ModalBackgroundComponent>;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService implements OnDestroy {
  // view container reference
  viewContainerRef: ViewContainerRef;
  // modal outlet
  modalOutlet: ModalOutletComponent;

  private _modalItems: IModalItem[] = [];

  // subscriptions
  private _subscriptions: Subscription = new Subscription();
  disableClose = false;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /**
   * open component
   * @param component component
   * @param options modal options
   * @param second set true to open second modal
   */
  open<T>(component: Type<T>, options: ModalOptions = {}): void {
    const { position, data, onClose } = options;
    this.createModal(component, data, onClose, position);
  }

  /**
   * close activated modal
   */
  close(returnValue?: any): void {
    const topModal = this._modalItems.pop();
    if (topModal) {
      setTimeout(() => {
        this.destroyModal(topModal);

        if (topModal.onClose) {
          topModal.onClose(returnValue);
        }

        if (!this._modalItems.length) {
          this.viewContainerRef.clear();
        }
      });
    }
  }

  /**
   * create background
   * @param second set true to open second modal
   */
  private createBackground(): ComponentRef<ModalBackgroundComponent> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(ModalBackgroundComponent);
    const background = this.viewContainerRef.createComponent(factory);
    this.subscribeBackgroundClose(background);
    return background;
  }

  /**
   * create content wrapper
   * @param position modal position
   */
  private createContentWrapper(position: ModalPosition): ComponentRef<ModalContentWrapperComponent> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(ModalContentWrapperComponent);
    const contentWrapper = this.viewContainerRef.createComponent(factory);
    contentWrapper.changeDetectorRef.detectChanges();

    if (position === 'center') {
      this.modalOutlet.position = 'center';
    } else {
      this.modalOutlet.position = 'top';
    }

    return contentWrapper;
  }

  /**
   * create modal component
   * @param component component
   * @param data data
   * @param onClose on close callback
   * @param second set true to open second modal
   */
  private createModal<T>(component: Type<T>, data: any, onClose: (returns?: any) => void, position: ModalPosition): void {
    const background = this.createBackground();
    const contentWrapper = this.createContentWrapper(position);
    const wrapperViewContainerRef = contentWrapper.instance.viewContainerRef;

    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    const injector = Injector.create({
      providers: [
        {
          provide: MODAL_DATA,
          useValue: data
        }
      ]
    });

    wrapperViewContainerRef.clear();
    wrapperViewContainerRef.createComponent(factory, 0, injector);

    const modalItem: IModalItem = {
      background: background,
      contentWrapper: contentWrapper,
      onClose: onClose
    };

    this._modalItems.push(modalItem);
  }

  /**
   * subscribe background close
   * @param background background component ref
   */
  private subscribeBackgroundClose(background: ComponentRef<ModalBackgroundComponent>): void {
    if (this.disableClose) {
      const sub = background.instance.backgroundClose.subscribe(() => this.close(null));
      this._subscriptions.add(sub);
    }
  }

  /**
   * destroy modal
   * @param second destroy second modal
   */
  private destroyModal(modalItem: IModalItem): void {
    modalItem.background?.destroy();
    modalItem.contentWrapper?.destroy();
  }
}
