<svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18">
  <g transform="translate(1)">
    <line
      id="선_22"
      data-name="선 22"
      x2="20"
      transform="translate(0 1)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      id="선_23"
      data-name="선 23"
      x2="20"
      transform="translate(0 9)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <line
      id="선_24"
      data-name="선 24"
      x2="20"
      transform="translate(0 17)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </g>
</svg>
