import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../services/components/app.service';
import { map } from 'rxjs/operators';
import { StorageService } from '../services/storage/storage.service';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class MainGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: MsalService,
    private appService: AppService,
    private storageService: StorageService)
  { }

  /**
   * can activate main router when user is signed in
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log('MainGuard -> canActivate');
    // get user form local storage
    this.appService.user = this.storageService.user;

    // NOTE: below is old code, remove in future
    //return this.appService.isSignedIn$.pipe(
    //  map((signed) => {
    //    if (!signed) {
    //      this.router.navigate(['/signin-oidc']);
    //    }

    //    return signed;
    //  })
    //);

    const isSignedIn = this.authService.instance.getAllAccounts().length > 0;
    if (!isSignedIn) {
      this.storageService.user = null;
      this.appService.user = null;
      this.router.navigate(['signin-oidc'], { queryParams: { returnUrl: state.url } });
    }
    else {
      this.appService.user = this.storageService.user;
    }

    return isSignedIn;
  }
}
