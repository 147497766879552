<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="alert-octagon" transform="translate(0.909 0.909)">
    <path
      id="패스_77"
      data-name="패스 77"
      d="M7.145,1.818h7.527L20,7.145v7.527L14.673,20H7.145L1.818,14.673V7.145Z"
      transform="translate(-1.818 -1.818)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="패스_78"
      data-name="패스 78"
      d="M10.909,7.273v3.636"
      transform="translate(-1.818 -1.818)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="패스_79"
      data-name="패스 79"
      d="M10.909,14.545h.009"
      transform="translate(-1.818 -1.818)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </g>
</svg>
