import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-message-mobile-outlet',
  templateUrl: './message-mobile-outlet.component.html',
  styleUrls: ['./message-mobile-outlet.component.scss']
})
export class MessageMobileOutletComponent implements OnInit, AfterViewInit, OnDestroy {
  // view container ref
  @ViewChild('container', { read: ViewContainerRef }) public viewContainerRef: ViewContainerRef;

  constructor(private messageService: MessageService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.messageService.attachDestroyedHandler(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * set viewContainerRef to service when view initialized
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.messageService.mobileViewContainerRef = this.viewContainerRef;
      this.messageService.copyMessageItem();
    });
  }

  ngOnDestroy(): void {
    this.messageService.destroyMobileMessageItemOnly();
    this.messageService.detachDestroyedHandler();
  }
}
