<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="그룹_86" data-name="그룹 86">
    <g id="그룹_85" data-name="그룹 85">
      <rect id="사각형_310" data-name="사각형 310" width="2" height="2" transform="translate(9 4)" fill="#80d0ff" />
    </g>
  </g>
  <g id="그룹_88" data-name="그룹 88">
    <g id="그룹_87" data-name="그룹 87">
      <rect id="사각형_311" data-name="사각형 311" width="2" height="2" transform="translate(5 4)" fill="#80d0ff" />
    </g>
  </g>
  <g id="그룹_90" data-name="그룹 90">
    <g id="그룹_89" data-name="그룹 89">
      <rect id="사각형_312" data-name="사각형 312" width="2" height="2" transform="translate(9 8)" fill="#80d0ff" />
    </g>
  </g>
  <g id="그룹_92" data-name="그룹 92">
    <g id="그룹_91" data-name="그룹 91">
      <rect id="사각형_313" data-name="사각형 313" width="2" height="2" transform="translate(5 8)" fill="#80d0ff" />
    </g>
  </g>
  <g id="그룹_94" data-name="그룹 94">
    <g id="그룹_93" data-name="그룹 93">
      <rect id="사각형_314" data-name="사각형 314" width="2" height="2" transform="translate(9 12)" fill="#80d0ff" />
    </g>
  </g>
  <g id="그룹_96" data-name="그룹 96">
    <g id="그룹_95" data-name="그룹 95">
      <rect id="사각형_315" data-name="사각형 315" width="2" height="2" transform="translate(5 12)" fill="#80d0ff" />
    </g>
  </g>
  <g id="그룹_98" data-name="그룹 98">
    <g id="그룹_97" data-name="그룹 97">
      <rect
        id="사각형_316"
        data-name="사각형 316"
        width="2"
        height="2"
        transform="translate(9 15.999)"
        fill="#80d0ff"
      />
    </g>
  </g>
  <g id="그룹_100" data-name="그룹 100">
    <g id="그룹_99" data-name="그룹 99">
      <rect
        id="사각형_317"
        data-name="사각형 317"
        width="2"
        height="2"
        transform="translate(5 15.999)"
        fill="#80d0ff"
      />
    </g>
  </g>
  <g id="그룹_102" data-name="그룹 102">
    <g id="그룹_101" data-name="그룹 101">
      <path id="패스_26" data-name="패스 26" d="M0,0V2H1V24H15V2h1V0ZM13,22H9.5V20h-3v2H3V2H13V22Z" fill="#80d0ff" />
    </g>
  </g>
  <g id="그룹_104" data-name="그룹 104">
    <g id="그룹_103" data-name="그룹 103">
      <path
        id="패스_27"
        data-name="패스 27"
        d="M20.5,10A3.5,3.5,0,0,0,17,13.5v3a3.489,3.489,0,0,0,2.5,3.338V24h2V19.838A3.489,3.489,0,0,0,24,16.5v-3A3.5,3.5,0,0,0,20.5,10ZM22,16.5a1.5,1.5,0,0,1-3,0v-3a1.5,1.5,0,1,1,3,0Z"
        fill="#80d0ff"
      />
    </g>
  </g>
</svg>
