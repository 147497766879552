/**
 * returns true when 'text' starts with 'searchString'. Comparison is done in a case insensitive way.
 * @param text the whole text
 * @param searchString the string to match in the 'text'
 */
export function startsWithCaseInsensitive(text: string, searchString: string): boolean {
  if (text && !searchString) {
    return false;
  }
  if (!text && searchString) {
    return false;
  }

  return text.toLocaleLowerCase().startsWith(searchString.toLocaleLowerCase());
}

/**
 * returns true when 'text' ends with 'searchString'. Comparison is done in a case insensitive way.
 * @param text the whole text
 * @param searchString the string to match in the 'text'
 */
export function endsWithCaseInsensitive(text: string, searchString: string): boolean {
  if (text && !searchString) {
    return false;
  }
  if (!text && searchString) {
    return false;
  }

  return text.toLocaleLowerCase().endsWith(searchString.toLocaleLowerCase());
}
