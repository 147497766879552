<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g transform="translate(-0.727 -0.727)">
    <circle
      id="타원_9"
      data-name="타원 9"
      cx="7.273"
      cy="7.273"
      r="7.273"
      transform="translate(1.455 1.455)"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="패스_38"
      data-name="패스 38"
      d="M8.727,5.818,5.818,8.727l2.909,2.909"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="패스_39"
      data-name="패스 39"
      d="M11.636,8.727H5.818"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </g>
</svg>
