<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g transform="translate(-0.727 -0.727)">
    <rect
      id="사각형_250"
      data-name="사각형 250"
      width="13.091"
      height="13.091"
      rx="1.455"
      transform="translate(2.182 2.909)"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="패스_8"
      data-name="패스 8"
      d="M11.636,1.455V4.364"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="패스_9"
      data-name="패스 9"
      d="M5.818,1.455V4.364"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="패스_10"
      data-name="패스 10"
      d="M2.182,7.273H15.273"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </g>
</svg>
