<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g transform="translate(0.268,-0.489)" id="rotate-ccw">
    <path
      fill="none"
      stroke="#1c88c8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M 0.714,2.854 V 7.135 H 4.995"
    />
    <path
      fill="none"
      stroke="#1c88c8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M 2.5,10.7 A 6.422,6.422 0 1 0 4.02,4.021 L 0.714,7.135"
    />
  </g>
</svg>
