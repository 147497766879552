import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MESSAGE_ITEM_ANIMATION } from '../../../utils/animation.util';

const { messageInterval, messageIntervalAlarm } = environment;

export type MessageType = 'success' | 'alarm' | 'error';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss'],
  animations: [MESSAGE_ITEM_ANIMATION]
})
export class MessageItemComponent implements OnInit, OnDestroy {
  // message
  @Input() set message(message: string | string[]) {
    this._message = message;
    this._startLoop();
  }
  // message type
  @Input() @HostBinding('attr.ts-message-type') type: MessageType;
  // message close emitter
  @Output() messageClose: EventEmitter<void> = new EventEmitter<void>();
  // shake animation
  @HostBinding('@shake') animation = 'shake';
  // alarm index
  index = 0;
  // timer
  private _timer;
  // message
  private _message: string | string[];

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    clearInterval(this._timer);
  }

  get currentMessage(): string {
    return this.hasMultipleMessage ? this.message[this.index] : (this.message as string);
  }

  get hasMultipleMessage(): boolean {
    return this.message instanceof Array;
  }

  get hasNext(): boolean {
    return this.index < this.message.length - 1;
  }

  get hasPrev(): boolean {
    return this.index > 0;
  }

  get message(): string | string[] {
    return this._message;
  }

  /**
   * start loop when message is array
   */
  private _startLoop(): void {
    if (this.hasMultipleMessage) {
      const interval = this.type === 'alarm' ? messageIntervalAlarm : messageInterval;

      this._timer = setTimeout(() => {
        this.index = this.index >= this.message.length - 1 ? 0 : this.index + 1;
        this._startLoop();
      }, interval);
    }
  }
}
