<!-- message left -->
<div class="ldm-message-left">
  <!-- icon -->
  <ng-container [ngSwitch]="type">
    <app-icon-exclamation *ngSwitchCase="'alarm'"></app-icon-exclamation>
  </ng-container>

  <!-- message -->
  <div class="ldm-message">
    {{ currentMessage }}
  </div>
</div>

<!-- right -->
<div class="ldm-message-right">
  <ng-container *ngIf="type === 'alarm' && hasMultipleMessage">
    <div class="ldm-message-paginator">
      <!-- left -->
      <app-icon-chevron-right
        (click)="hasPrev ? (index = index - 1) : null"
        [class.disabled]="!hasPrev"
        [clickable]="true"
        class="ldm-left-chevron"
      ></app-icon-chevron-right>
      <!-- display -->
      <span>{{ index + 1 }}/{{ message.length }}</span>
      <!-- right -->
      <app-icon-chevron-right
        (click)="hasNext ? (index = index + 1) : null"
        [class.disabled]="!hasNext"
        [clickable]="true"
      ></app-icon-chevron-right>
    </div>

    <!-- divider -->
    <app-divider color="transparent" height="26" width="1"></app-divider>
  </ng-container>

  <!-- close -->
  <app-icon-close (click)="messageClose.emit()" [clickable]="true"></app-icon-close>
</div>

<!-- message dots -->
<div *ngIf="hasMultipleMessage" class="ldm-message-dots">
  <div
    (click)="index = i"
    *ngFor="let item of message; let i = index"
    [class.active]="i === index"
    class="ldm-message-dot-item"
  ></div>
</div>

<!-- close -->
<app-icon-close (click)="messageClose.emit()" [clickable]="true" class="ldm-close-mobile"></app-icon-close>
