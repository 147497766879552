<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g transform="translate(0 0)">
    <g transform="translate(3.781 11.773)">
      <rect width="8.438" height="1.176" fill="#8693ad" />
    </g>
    <g transform="translate(3.781 13.886)">
      <rect width="8.438" height="1.176" fill="#8693ad" />
    </g>
    <g transform="translate(3.781 9.659)">
      <rect width="8.438" height="1.176" fill="#8693ad" />
    </g>
    <g transform="translate(0 0)">
      <path
        d="M15.838,6.64,8.307.114a.469.469,0,0,0-.614,0L.162,6.64A.469.469,0,0,0,0,6.995v8.537A.469.469,0,0,0,.469,16H2.844V9.19a.469.469,0,0,1,.469-.469h9.375a.469.469,0,0,1,.469.469V16h2.375A.469.469,0,0,0,16,15.531V6.995A.469.469,0,0,0,15.838,6.64ZM8.882,5.263H7.118a.469.469,0,0,1,0-.937H8.882a.469.469,0,0,1,0,.938Z"
        transform="translate(0 0)"
        fill="#8693ad"
      />
    </g>
  </g>
</svg>
