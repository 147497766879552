import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { MessageType } from '../message/message-item/message-item.component';
import { SubscriptionInventory } from '../../utils/subscribe.util';
import { MessageService } from '../message/message.service';
import { SuccessMessageItemComponent } from './success-message-item/success-message-item.component';

@Injectable({
  providedIn: 'root'
})
export class SuccessMessageService {
  // view container ref map
  // to allow multiple outlets, use map
  private _viewContainerRefMap: { [k: string]: ViewContainerRef } = {};
  // message item ref map
  // allow each outlet to create message
  // all message item shares same contents and state
  private _messageItemRefMap: { [k: string]: ComponentRef<SuccessMessageItemComponent> } = {};
  // subscription inventory
  private _inventory: SubscriptionInventory = new SubscriptionInventory();

  constructor(private memberService: MessageService, private componentFactoryResolver: ComponentFactoryResolver) {}

  /**
   * append view container
   * @param id view container id
   * @param viewContainerRef view container ref
   */
  appendViewContainer(id: string, viewContainerRef: ViewContainerRef): void {
    this._viewContainerRefMap[id] = viewContainerRef;
    this._cloneMessageOnAppend(id);
  }

  /**
   * if already created message item exists,
   * clone message on new view container ref appended
   * @param appendedId appended container id
   */
  private _cloneMessageOnAppend(appendedId: string): void {
    const id = Object.keys(this._messageItemRefMap).find((item) => this._messageItemRefMap[item]);

    if (id) {
      const message = this._messageItemRefMap[id];
      const factory = this.componentFactoryResolver.resolveComponentFactory(SuccessMessageItemComponent);
      const messageItemRef = this._viewContainerRefMap[appendedId].createComponent(factory);

      messageItemRef.instance.type = message.instance.type;
      messageItemRef.instance.message = message.instance.message;
      // when cloning, also clone remaining count
      messageItemRef.instance.count = message.instance.count;
      messageItemRef.changeDetectorRef.detectChanges();

      this._messageItemRefMap[appendedId] = messageItemRef;
      // re-create subscription for close
      this._subscribeForClose();
    }
  }

  /**
   * destroy view container by id
   * @param id view container id
   */
  destroyViewContainer(id: string): void {
    this._messageItemRefMap[id]?.destroy();
    this._messageItemRefMap[id] = null;
    this._viewContainerRefMap[id] = null;
  }

  /**
   * open message
   * @param type message type
   * @param message message
   */
  open(type: MessageType, message: string): void {
    this.memberService.destroyMessageItem();
    this.destroyMessageItem();
    this._createMessageItems(type, message);
    this._subscribeForClose();
  }

  /**
   * displays Success message
   * @param message success message
   */
  displaySuccess(message: string): void {
    this.open('success', message);
  }

  /**
   * displays Error message
   * @param message error message
   */
  displayError(message: string): void {
    this.open('error', message);
  }

  /**
   * create message items for every view containers
   * @param type message type
   * @param message message
   */
  private _createMessageItems(type: MessageType, message: string): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(SuccessMessageItemComponent);

    Object.keys(this._viewContainerRefMap).forEach((id) => {
      const messageItemRef = this._viewContainerRefMap[id]?.createComponent(factory);

      if (messageItemRef) {
        messageItemRef.instance.type = type;
        messageItemRef.instance.message = message;
        messageItemRef.changeDetectorRef.detectChanges();
      }

      this._messageItemRefMap[id]?.destroy();
      this._messageItemRefMap[id] = messageItemRef;
    });
  }

  /**
   * subscribe for close emitter
   */
  private _subscribeForClose(): void {
    const subs = Object.keys(this._messageItemRefMap)
      .filter((id) => this._messageItemRefMap[id])
      .map((id) =>
        this._messageItemRefMap[id].instance.messageClose.subscribe(() => {
          this.destroyMessageItem();
        })
      );

    this._inventory.store('_subscribeForClose', subs);
  }

  /**
   * destroy all message items
   */
  destroyMessageItem(): void {
    // destroy all items
    Object.keys(this._messageItemRefMap).forEach((id) => {
      this._messageItemRefMap[id]?.destroy();
      this._messageItemRefMap[id] = null;
    });
  }
}
