<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g transform="translate(-0.727 -0.727)">
    <path
      d="M2.182,6.545,8.727,1.455l6.545,5.091v8A1.455,1.455,0,0,1,13.818,16H3.636a1.455,1.455,0,0,1-1.455-1.455Z"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      d="M6.545,16V8.727h4.364V16"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </g>
</svg>
