import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInactivityAlertModalComponent } from './user-inactivity-alert-modal.component';
import { FlatButtonModule } from 'src/app/shared-components/flat-button/flat-button.module';
import { LoadingReplacerModule } from 'src/app/shared-components/loading-replacer/loading-replacer.module';
import { ModalModule } from 'src/app/shared-components/modal/modal.module';

@NgModule({
  declarations: [UserInactivityAlertModalComponent],
  exports: [UserInactivityAlertModalComponent],
  entryComponents: [UserInactivityAlertModalComponent],
  imports: [CommonModule, ModalModule, FlatButtonModule, LoadingReplacerModule]
})
export class UserInactivityAlertModalModule {}
