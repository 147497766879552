<app-modal-container>
  <!-- header -->
  <app-modal-header>
    Inactivity Alert
  </app-modal-header>

  <!-- content -->
  <app-modal-content>
    <span>
      Due to inactivity, your session will expire in <span class="hightlight"><i><b>{{remainingMinutes}}</b> minute{{minutesSuffix}}</i>, <i><b>{{remainingSeconds}}</b> second{{secondsSuffix}}</i></span> and all unsaved work will be lost
    </span>
  </app-modal-content>

</app-modal-container>
