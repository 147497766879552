<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g transform="translate(0 -.029)">
    <path
      id="패스_11"
      data-name="패스 11"
      d="M11.333,14V12.667A2.667,2.667,0,0,0,8.667,10H3.333A2.667,2.667,0,0,0,.667,12.667V14"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <circle
      id="타원_4"
      data-name="타원 4"
      cx="2.667"
      cy="2.667"
      r="2.667"
      transform="translate(3.333 2)"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="패스_12"
      data-name="패스 12"
      d="M15.333,14V12.667a2.667,2.667,0,0,0-2-2.58"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      id="패스_13"
      data-name="패스 13"
      d="M10.667,2.087a2.667,2.667,0,0,1,0,5.167"
      fill="none"
      stroke="#8693ad"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </g>
</svg>
