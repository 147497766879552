<!-- loading spinner (until user is signed in) -->
<app-spinner *ngIf="!(isLoginCompleted | async) && !(isAppInFailedState | async)" [align]="'center'" [size]="70" borderWidth="7" periodSec="1.3"></app-spinner>

<!-- router -->
<router-outlet></router-outlet>

<!-- modal outlet -->
<app-modal-outlet></app-modal-outlet>

<!-- success message outlet -->
<app-success-message-outlet [style.display]="isMobile ? 'none' : 'flex'"></app-success-message-outlet>
