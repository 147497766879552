import { Component, OnInit } from '@angular/core';
import { IconBaseComponent } from '../icon-base/icon-base.component';

@Component({
  selector: 'app-icon-arrow-down',
  templateUrl: './icon-arrow-down.component.html',
  styleUrls: ['./icon-arrow-down.component.scss']
})
export class IconArrowDownComponent extends IconBaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
