<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <path
    style="fill: none; stroke: #1c88c8; stroke-width: 1.5; stroke-linecap: round; stroke-linejoin: round;"
    d="M 7.205,2.345 H 2.185 A 1.434,1.434 0 0 0 0.75,3.776 v 10.04 a 1.434,1.434 0 0 0 1.434,1.434 h 10.04 a 1.434,1.434 0 0 0 1.434,-1.434 V 8.799"
  />
  <path
    style="fill: none; stroke: #1c88c8; stroke-width: 1.5; stroke-linecap: round; stroke-linejoin: round;"
    d="M 12.583,1.269 A 1.521,1.521 0 0 1 14.734,3.42 L 7.922,10.233 5.053,10.95 5.77,8.081 Z"
  />
</svg>
