import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {
  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  /**
   * close modal
   */
  closeModal(): void {
    this.modalService.close(null);
  }
}
