<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g data-name="architecture-and-city (2)">
    <g id="그룹_5" data-name="그룹 5">
      <g id="그룹_4" data-name="그룹 4">
        <path
          id="패스_21"
          data-name="패스 21"
          d="M23,18V16H21V0H3V16H1v2H3v4H1v2H23V22H21V18ZM7,22H5V18H7Zm4,0H9V18h2Zm4,0H13V18h2Zm4,0H17V18h2Zm0-6H5V2H19V16Z"
          fill="#80d0ff"
        />
      </g>
    </g>
    <g id="그룹_7" data-name="그룹 7">
      <g id="그룹_6" data-name="그룹 6">
        <rect
          id="사각형_271"
          data-name="사각형 271"
          width="2"
          height="2"
          transform="translate(6.999 12)"
          fill="#80d0ff"
        />
      </g>
    </g>
    <g id="그룹_9" data-name="그룹 9">
      <g id="그룹_8" data-name="그룹 8">
        <rect id="사각형_272" data-name="사각형 272" width="2" height="2" transform="translate(11 12)" fill="#80d0ff" />
      </g>
    </g>
    <g id="그룹_11" data-name="그룹 11">
      <g id="그룹_10" data-name="그룹 10">
        <rect id="사각형_273" data-name="사각형 273" width="2" height="2" transform="translate(15 12)" fill="#80d0ff" />
      </g>
    </g>
    <g id="그룹_13" data-name="그룹 13">
      <g id="그룹_12" data-name="그룹 12">
        <rect
          id="사각형_274"
          data-name="사각형 274"
          width="2"
          height="2"
          transform="translate(6.999 8)"
          fill="#80d0ff"
        />
      </g>
    </g>
    <g id="그룹_15" data-name="그룹 15">
      <g id="그룹_14" data-name="그룹 14">
        <rect id="사각형_275" data-name="사각형 275" width="2" height="2" transform="translate(11 8)" fill="#80d0ff" />
      </g>
    </g>
    <g id="그룹_17" data-name="그룹 17">
      <g id="그룹_16" data-name="그룹 16">
        <rect id="사각형_276" data-name="사각형 276" width="2" height="2" transform="translate(15 8)" fill="#80d0ff" />
      </g>
    </g>
    <g id="그룹_19" data-name="그룹 19">
      <g id="그룹_18" data-name="그룹 18">
        <rect
          id="사각형_277"
          data-name="사각형 277"
          width="2"
          height="2"
          transform="translate(6.999 4)"
          fill="#80d0ff"
        />
      </g>
    </g>
    <g id="그룹_21" data-name="그룹 21">
      <g id="그룹_20" data-name="그룹 20">
        <rect id="사각형_278" data-name="사각형 278" width="2" height="2" transform="translate(11 4)" fill="#80d0ff" />
      </g>
    </g>
    <g id="그룹_23" data-name="그룹 23">
      <g id="그룹_22" data-name="그룹 22">
        <rect id="사각형_279" data-name="사각형 279" width="2" height="2" transform="translate(15 4)" fill="#80d0ff" />
      </g>
    </g>
  </g>
</svg>
