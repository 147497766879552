<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <path
    d="m 18.972993,9.195466 v 0.821 a 8.922,8.922 0 1 1 -5.291,-8.155"
    fill="none"
    stroke="#ffffff"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="m 18.972993,2.878466 -8.923,8.931 -2.6760003,-2.677"
    fill="none"
    stroke="#ffffff"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
