import { ComponentFactoryResolver, ComponentRef, Injectable, OnDestroy, ViewContainerRef } from '@angular/core';
import { MessageItemComponent, MessageType } from './message-item/message-item.component';
import { SubscriptionInventory, SubscriptionKeys } from '../../utils/subscribe.util';

@Injectable({
  providedIn: 'root'
})
export class MessageService implements OnDestroy {
  // view container reference
  private static viewContainerRef: ViewContainerRef;
  // mobile view container references
  // mobile view container can be multiple
  // so this should be array
  private static mobileViewContainerRefs: ViewContainerRef[] = [];
  // created message item
  private messageItem: ComponentRef<MessageItemComponent>;
  // created mobile message items
  private mobileMessageItems: ComponentRef<MessageItemComponent>[] = [];
  // inventory
  private inventory: SubscriptionInventory = new SubscriptionInventory<SubscriptionKeys>();
  // destroyed handler
  private _destroyedHandler: () => void;
  // position correct handler
  private _positionCorrectHandler: () => void;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnDestroy(): void {
    this.inventory.unSubscribeAll();
  }

  /**
   * set view container ref
   * @param ref ref
   */
  set viewContainerRef(ref: ViewContainerRef) {
    MessageService.viewContainerRef = ref;
  }

  /**
   * get view container ref
   */
  get viewContainerRef(): ViewContainerRef {
    return MessageService.viewContainerRef;
  }

  set mobileViewContainerRef(ref: ViewContainerRef) {
    const index = MessageService.mobileViewContainerRefs.indexOf(ref);

    if (index !== -1) {
      MessageService.mobileViewContainerRefs.splice(index, 1);
    }

    MessageService.mobileViewContainerRefs.push(ref);
  }

  get mobileViewContainerRefs(): ViewContainerRef[] {
    return MessageService.mobileViewContainerRefs;
  }

  /**
   * open message
   * @param type message type
   * @param message message
   */
  open(type: MessageType, message: string | string[]): void {
    this.destroyMessageItem();
    this.createMessageItem();
    this.createMobileMessageItem();

    if (this.messageItem) {
      // set inputs and detect changes
      this.messageItem.instance.type = type;
      this.messageItem.instance.message = message;
      this.messageItem.changeDetectorRef.detectChanges();
    }

    if (this.mobileMessageItems.length > 0) {
      this.mobileMessageItems.forEach((item) => {
        // set inputs and detect changes
        item.instance.type = type;
        item.instance.message = message;
        item.changeDetectorRef.detectChanges();
      });
    }

    this.subscribeForClose();
  }

  /**
   * copy main message item if exists
   */
  copyMessageItem(): void {
    if (this.messageItem) {
      this.createMobileMessageItem();

      this.mobileMessageItems.forEach((item) => {
        // set inputs and detect changes
        item.instance.type = this.messageItem.instance.type;
        item.instance.message = this.messageItem.instance.message;
        item.changeDetectorRef.detectChanges();
      });

      this.subscribeForClose();
    }
  }

  /**
   * create message item
   */
  private createMessageItem(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(MessageItemComponent);

    this.messageItem = this.viewContainerRef?.createComponent(factory);
  }

  /**
   * create mobile message item
   */
  private createMobileMessageItem(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(MessageItemComponent);

    // destroy previous items
    this.mobileMessageItems.forEach((item) => item.destroy());

    this.mobileMessageItems = this.mobileViewContainerRefs.map((ref) => ref.createComponent(factory));
  }

  /**
   * subscribe for close emitter
   */
  private subscribeForClose(): void {
    this.inventory.unSubscribe('messageClose');

    const sub1 = this.messageItem?.instance.messageClose.subscribe(() => this.destroyMessageItem());

    const sub2 = this.mobileMessageItems.map((item) => {
      return item.instance.messageClose.subscribe(() => this.destroyMessageItem());
    });

    this.inventory.store(
      'messageClose',
      [sub1, ...sub2].filter((item) => item)
    );
  }

  /**
   * destroy message item
   */
  destroyMessageItem(): void {
    this.messageItem?.destroy();
    this.messageItem = null;
    this.destroyMobileMessageItemOnly();
  }

  destroyMobileMessageItemOnly(): void {
    this.mobileMessageItems.forEach((item) => item.destroy());
    this.mobileMessageItems = [];
    this._callDestroyHandler();
  }

  /**
   * attach destroyed handler
   * @param handler handler
   */
  attachDestroyedHandler(handler: () => void): void {
    this._destroyedHandler = handler;
  }

  /**
   * detach destroyed handler
   */
  detachDestroyedHandler(): void {
    this._destroyedHandler = null;
  }

  private _callDestroyHandler(): void {
    if (this._destroyedHandler) {
      this._destroyedHandler();
    }
  }

  /**
   * attach position correct handler
   * @param handler handler
   */
  attachPositionCorrectHandler(handler: () => void): void {
    this._positionCorrectHandler = handler;
  }

  /**
   * call position correct handler
   */
  callPositionCorrectHandler(): void {
    if (this._positionCorrectHandler) {
      this._positionCorrectHandler();
    }
  }

  /**
   * detach position correct handler
   */
  detachPositionCorrectHandler(): void {
    this._positionCorrectHandler = null;
  }
}
