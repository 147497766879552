<svg xmlns="http://www.w3.org/2000/svg" width="10.828" height="10.828" viewBox="0 0 10.828 10.828">
  <g data-name="close 8px" transform="translate(1.496 1.414)">
    <line
      x1="8"
      y1="8"
      transform="translate(-0.082)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
    <line
      x1="8"
      y2="8"
      transform="translate(-0.082)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
  </g>
</svg>
