<!-- message left -->
<div class="ldm-message-left">
  <!-- icon -->
  <ng-container [ngSwitch]="type">
    <app-icon-exclamation *ngSwitchCase="'error'"></app-icon-exclamation>
    <app-icon-check-outline *ngSwitchCase="'success'"></app-icon-check-outline>
  </ng-container>

  <!-- message -->
  <div class="ldm-message">
    {{ message }}
  </div>
</div>

<!-- right -->
<div class="ldm-message-right">
  <!-- close -->
  <app-icon-close (click)="messageClose.emit()" [clickable]="true"></app-icon-close>
</div>
