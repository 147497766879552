<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="그룹_105" data-name="그룹 105">
    <path
      id="패스_28"
      data-name="패스 28"
      d="M12,0,0,12l1.414,1.414L3,11.828V24H21V11.828l1.586,1.586L24,12Zm7,22H13V20a3,3,0,0,1,3-3h1V15H16a4.958,4.958,0,0,0-3,1.022V15.07a7.976,7.976,0,0,1,2.344-5.656L13.93,8a10.015,10.015,0,0,0-2.17,3.251A10,10,0,0,0,10.07,9L8.656,10.414a7.99,7.99,0,0,1,2.158,3.954A5.976,5.976,0,0,0,7,13v2a4,4,0,0,1,4,4V22H5V9.828l7-7,7,7Z"
      fill="#80d0ff"
    />
  </g>
</svg>
