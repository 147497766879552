<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <g transform="translate(-1.6 -1.6)">
    <path
      d="M7.2,16.8H4a1.6,1.6,0,0,1-1.6-1.6V4A1.6,1.6,0,0,1,4,2.4H7.2"
      fill="none"
      stroke="#26aed1"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
    <path
      d="M12.8,13.6l4-4-4-4"
      fill="none"
      stroke="#26aed1"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
    <path
      d="M16.8,9.6H7.2"
      fill="none"
      stroke="#26aed1"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
  </g>
</svg>
